import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$answers = 0;
Vue.prototype.$answersGood = 0;
Vue.prototype.$gameMode = 'keersommen_makkelijk';
Vue.prototype.$gameTafels = {
  '2': false,
  '3': false,
  '4': false,
  '5': false,
  '6': false,
  '7': false,
  '8': false,
  '9': false,
  '10': false,
  '11': false,
  '12': false,
  '13': false,
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')