<template>
  <div class="p-3">
    <b-row style="border-bottom:1px solid #000;">
      <b-col class="text-center">
        <h1 style="font-size:200%;">Amy's sommen</h1>
      </b-col>
    </b-row>

    <br/>
    <div class="tafels">
      <b-row>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['2']">2</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['3']">3</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['4']">4</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['5']">5</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['6']">6</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['7']">7</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['8']">8</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['9']">9</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['10']">10</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['11']">11</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['12']">12</b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-info" block :pressed.sync="tafels['13']">13</b-button>
        </b-col>
      </b-row>
    </div>

    <hr/>
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="gameMode" :aria-describedby="ariaDescribedby" name="some-radios" value="keer_makkelijk">
        keer makkelijk (25 goed)
      </b-form-radio>
      <b-form-radio v-model="gameMode" :aria-describedby="ariaDescribedby" name="some-radios" value="keer_normaal">
        keer normaal (32 goed)
      </b-form-radio>
      <b-form-radio v-model="gameMode" :aria-describedby="ariaDescribedby" name="some-radios" value="deel_makkelijk">
        deel makkelijk (25 goed)
      </b-form-radio>
      <b-form-radio v-model="gameMode" :aria-describedby="ariaDescribedby" name="some-radios" value="deel_normaal">
        deel normaal (32 goed)
      </b-form-radio>
    </b-form-group>

    <template v-if="answers>0">
      <hr/>
      <p>{{ answersGood }}/{{ answers }} vragen goed</p>
    </template>

    <b-button variant="success" block @click="startGame">START</b-button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'HomeView',
  components: {},

  data() {
    return {
      answers: Vue.prototype.$answers,
      answersGood: Vue.prototype.$answersGood,
      gameMode: Vue.prototype.$gameMode,

      tafels: Vue.prototype.$gameTafels,
    }
  },
  methods: {
    startGame: function () {
      Vue.prototype.$gameTafels = this.tafels
      this.$router.push('/tafels')
    }
  },
  watch: {
    gameMode: function (newValue) {
      Vue.prototype.$gameMode = newValue
    }
  }
}
</script>
